import React from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { Link } from 'gatsby';

import mq from '../styles/mq';
import typography, { rhythm, scale } from '../utils/typography';
import CategoryLink from './CategoryLink';
import TimeToRead from './TimeToRead';

const PostContainer = styled('article')`
  position: relative;
  padding: ${rhythm(3 / 2)};
  background: ${props => props.theme.backgroundDefaultColor};
  border-radius: 6px;
  margin-bottom: ${rhythm(1)};

  ${mq.medium} {
    transition: all 400ms;

    :hover {
      box-shadow: 0 -1px 40px 4px rgba(0, 0, 0, 0.1);
      transform: translate(0px, -4px);
    }
  }
`;

const fallbackFontStyles = css`
  font-family: Georgia, 'Times New Roman', serif;
  font-size: 2.1rem;
  letter-spacing: 1px;
  word-spacing: 0.8px;
  line-height: 2.814rem;
`;

const PostTitle = styled('h2')`
  ${fallbackFontStyles};

  .wf-active & {
    font-family: 'Domine', sans-serif;
    ${css(scale(1))};
    letter-spacing: initial;
    word-spacing: initial;
  }
`;

const PostSummary = styled('p')``;

const PostFooter = styled('footer')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PostMeta = styled('div')`
  ${css(scale(-1 / 5))};
  letter-spacing: 0.5px;
`;

const PostLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ListPost = ({
  link,
  title,
  date,
  descr,
  timeToRead,
  category,
  theme,
}) => (
  <PostContainer theme={theme}>
    <PostLink to={link}>
      {/*<PostTitle className="fallback">{title}</PostTitle>*/}
      <PostTitle>{title}</PostTitle>
      <PostSummary dangerouslySetInnerHTML={{ __html: descr }} />
      <PostFooter>
        <PostMeta>
          {date} &middot; <TimeToRead timeToRead={timeToRead} />
        </PostMeta>
        <CategoryLink category={category} />
      </PostFooter>
    </PostLink>
  </PostContainer>
);

ListPost.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  descr: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  author: PropTypes.object.isRequired,
};

export default withTheme(ListPost);
