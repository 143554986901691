import React from 'react';

import { graphql } from 'gatsby';

import ListPost from '../components/ListPost';

const Post = ({ node }) => (
  <ListPost
    link={node.fields.slug}
    title={node.frontmatter.title || node.fields.slug}
    date={node.frontmatter.date}
    descr={node.frontmatter.description || node.excerpt}
    timeToRead={node.timeToRead}
    category={node.frontmatter.category}
    author={node.frontmatter.author}
  />
);

export const query = graphql`
  fragment PostFragment on MarkdownRemark {
    excerpt
    fields {
      slug
    }
    frontmatter {
      date(formatString: "DD MMMM, YYYY")
      title
      category
      author {
        id
        name
      }
      description
    }
    timeToRead
  }
`;

export default Post;
